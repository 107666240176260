
@media (min-width: 1000px) {
    .FooterStyle {
        position: relative;
        left: 0;
        bottom: 0;
        right: 0;
    }
    .MainFooterStyle {
        min-height: 800px;
        overflow:hidden;
    }
}

.FooterContainer {
    display: block;
    background-color: #0d51a1;
    padding: 5vw 20vw 0 20vw;
}
.FooterContent {
    display: inline-block;
    color: white;
    width: 70%;
}
    .FooterContent h1 a {
        color: white;
        font-size: calc(12px + 1.5vw)
    }
    .FooterContent p {
        color: white;
        font-size: calc(8px + .3vw)
    }
    .FooterContent img {
        width: calc(45px + 1vw);
        margin: 20px 0 4px;
    }
    .FooterContent h2 {
        font-size: calc(6px + .3vw);
        margin: 20px 0 4px;
    }
    .FooterContent ul a{
        color:white;
    }
    .FooterContent ul a:hover {
        color:#8dc8e8;
    }
    .FooterContent li {
        display: inline;

        padding: 0 1vw 0 0;
    }
    .FooterContent hr{
        margin:1vw 0;
        padding:0;
    }

.FooterLogo img{
    float:right;
    width: calc(100px + 2vw);
}

